import { BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function LogoMitBlad({ size, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 97 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title ? title : ''}</title>
      <path
        d="M25.4331 2.064V18.816H22.0731V7.92L17.5851 18.816H15.0411L10.5291 7.92V18.816H7.16913V2.064H10.9851L16.3131 14.52L21.6411 2.064H25.4331Z"
        fill="#333333"
      />
      <path
        d="M30.4591 3.936C29.8671 3.936 29.3711 3.752 28.9711 3.384C28.5871 3 28.3951 2.528 28.3951 1.968C28.3951 1.408 28.5871 0.944 28.9711 0.576C29.3711 0.192 29.8671 0 30.4591 0C31.0511 0 31.5391 0.192 31.9231 0.576C32.3231 0.944 32.5231 1.408 32.5231 1.968C32.5231 2.528 32.3231 3 31.9231 3.384C31.5391 3.752 31.0511 3.936 30.4591 3.936ZM32.1151 5.52V18.816H28.7551V5.52H32.1151Z"
        fill="#333333"
      />
      <path
        d="M39.3468 8.28V14.712C39.3468 15.16 39.4508 15.488 39.6588 15.696C39.8828 15.888 40.2508 15.984 40.7628 15.984H42.3228V18.816H40.2108C37.3788 18.816 35.9628 17.44 35.9628 14.688V8.28H34.3788V5.52H35.9628V2.232H39.3468V5.52H42.3228V8.28H39.3468Z"
        fill="#333333"
      />
      <path
        d="M54.2434 10.224C55.1874 10.4 55.9634 10.872 56.5714 11.64C57.1794 12.408 57.4834 13.288 57.4834 14.28C57.4834 15.176 57.2594 15.968 56.8114 16.656C56.3794 17.328 55.7474 17.856 54.9154 18.24C54.0834 18.624 53.0994 18.816 51.9634 18.816H44.7394V2.064H51.6514C52.7874 2.064 53.7634 2.248 54.5794 2.616C55.4114 2.984 56.0354 3.496 56.4514 4.152C56.8834 4.808 57.0994 5.552 57.0994 6.384C57.0994 7.36 56.8354 8.176 56.3074 8.832C55.7954 9.488 55.1074 9.952 54.2434 10.224ZM48.0994 8.976H51.1714C51.9714 8.976 52.5874 8.8 53.0194 8.448C53.4514 8.08 53.6674 7.56 53.6674 6.888C53.6674 6.216 53.4514 5.696 53.0194 5.328C52.5874 4.96 51.9714 4.776 51.1714 4.776H48.0994V8.976ZM51.4834 16.08C52.2994 16.08 52.9314 15.888 53.3794 15.504C53.8434 15.12 54.0754 14.576 54.0754 13.872C54.0754 13.152 53.8354 12.592 53.3554 12.192C52.8754 11.776 52.2274 11.568 51.4114 11.568H48.0994V16.08H51.4834Z"
        fill="#333333"
      />
      <path d="M63.5213 1.056V18.816H60.1613V1.056H63.5213Z" fill="#333333" />
      <path
        d="M65.977 12.12C65.977 10.776 66.241 9.584 66.769 8.544C67.313 7.504 68.041 6.704 68.953 6.144C69.881 5.584 70.913 5.304 72.049 5.304C73.041 5.304 73.905 5.504 74.641 5.904C75.393 6.304 75.993 6.808 76.441 7.416V5.52H79.825V18.816H76.441V16.872C76.009 17.496 75.409 18.016 74.641 18.432C73.889 18.832 73.017 19.032 72.025 19.032C70.905 19.032 69.881 18.744 68.953 18.168C68.041 17.592 67.313 16.784 66.769 15.744C66.241 14.688 65.977 13.48 65.977 12.12ZM76.441 12.168C76.441 11.352 76.281 10.656 75.961 10.08C75.641 9.488 75.209 9.04 74.665 8.736C74.121 8.416 73.537 8.256 72.913 8.256C72.289 8.256 71.713 8.408 71.185 8.712C70.657 9.016 70.225 9.464 69.889 10.056C69.569 10.632 69.409 11.32 69.409 12.12C69.409 12.92 69.569 13.624 69.889 14.232C70.225 14.824 70.657 15.28 71.185 15.6C71.729 15.92 72.305 16.08 72.913 16.08C73.537 16.08 74.121 15.928 74.665 15.624C75.209 15.304 75.641 14.856 75.961 14.28C76.281 13.688 76.441 12.984 76.441 12.168Z"
        fill="#333333"
      />
      <path
        d="M82.2426 12.12C82.2426 10.776 82.5066 9.584 83.0346 8.544C83.5786 7.504 84.3146 6.704 85.2426 6.144C86.1706 5.584 87.2026 5.304 88.3386 5.304C89.2026 5.304 90.0266 5.496 90.8106 5.88C91.5946 6.248 92.2186 6.744 92.6826 7.368V1.056H96.0906V18.816H92.6826V16.848C92.2666 17.504 91.6826 18.032 90.9306 18.432C90.1786 18.832 89.3066 19.032 88.3146 19.032C87.1946 19.032 86.1706 18.744 85.2426 18.168C84.3146 17.592 83.5786 16.784 83.0346 15.744C82.5066 14.688 82.2426 13.48 82.2426 12.12ZM92.7066 12.168C92.7066 11.352 92.5466 10.656 92.2266 10.08C91.9066 9.488 91.4746 9.04 90.9306 8.736C90.3866 8.416 89.8026 8.256 89.1786 8.256C88.5546 8.256 87.9786 8.408 87.4506 8.712C86.9226 9.016 86.4906 9.464 86.1546 10.056C85.8346 10.632 85.6746 11.32 85.6746 12.12C85.6746 12.92 85.8346 13.624 86.1546 14.232C86.4906 14.824 86.9226 15.28 87.4506 15.6C87.9946 15.92 88.5706 16.08 89.1786 16.08C89.8026 16.08 90.3866 15.928 90.9306 15.624C91.4746 15.304 91.9066 14.856 92.2266 14.28C92.5466 13.688 92.7066 12.984 92.7066 12.168Z"
        fill="#333333"
      />
      <path
        d="M2.68785 5.4912C2.68205 5.46337 2.70058 5.43632 2.72863 5.43167L5.46147 4.97922C5.49193 4.97418 5.51963 4.99768 5.51963 5.02855L5.51967 18.5965C5.51967 18.6566 5.43299 18.6655 5.42072 18.6067L2.68785 5.4912Z"
        fill="#333333"
      />
      <path
        d="M0.019948 9.95805C0.00894703 9.93261 0.0207292 9.90308 0.0462172 9.89221L1.84208 9.12597C1.8712 9.11355 1.90438 9.13097 1.9107 9.16199L3.80217 18.4532C3.81416 18.5121 3.73114 18.5382 3.70728 18.483L0.019948 9.95805Z"
        fill="#333333"
      />
    </BaseSvgStyle>
  );
}

export default LogoMitBlad;
